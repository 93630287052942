.page {
  position: relative;
  padding: 20px 5px;
  margin: 10px;
  background-color: #fff;
}
.header {
  display: flex;
  justify-content: space-between;
  height: 35px;
  font-size: 13px;
  line-height: 30px;
}
.footer {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #c0c0c0;
}
.content {
  padding: 10px 0;
  border-top: 1px solid #000;
}
.tips {
  font-size: 13px;
  color: #646262;
  > span {
    font-weight: 600;
  }
}
.img {
  position: absolute;
  bottom: 110px;
  right: 0px;
  height: 120px;
  width: 120px;
  z-index: 100;
}
.logo {
  width: 100px;
  height: 30px;
}